export const useOFetchCustom = (
    url: string,
): {
    data: Ref<any>, 
    error: Ref<null|Error>, 
    loading: Ref<boolean>, 
    setUrl: (newUrl: string) => void,
    post: (body?: any, options?: {headers?: Record<string, string|number>, onSuccess?: (res: any) => void, onError?: (err: any) => void, onFinish?: () => void}) => void,
    get: (params?: any, options?: {headers?: Record<string, string|number>, onSuccess?: (res: any) => void, onError?: (err: any) => void, onFinish?: () => void}) => void,
    put: (body?: any, options?: {headers?: Record<string, string|number>, onSuccess?: (res: any) => void, onError?: (err: any) => void, onFinish?: () => void}) => void,
    destroy: (body?:any, options?: {onSuccess?: (res: any) => void, onError?: (err: any) => void, onFinish?: () => void}) => void,
} => {

    const urlRef = ref(url)
    const data = ref(null);
    const error = ref(null);
    const loading = ref(false);
    const {loadingShow,loadingHide}=useLoading()

    const finalUrl = computed(() => '/api/' + urlRef.value)


    const client = useSanctumClient()    

      function setUrl(newUrl: string) {
        urlRef.value = newUrl
    }
    

    const f = (options: Object, extraOptions?: {onSuccess?: (res: any) => void, onError?: (err: any) => void, onFinish?: () => void, headers?:Record<string, string|number>}) => {

        const {$i18n}=useNuxtApp()
        const locale  = $i18n.locale.value
        loading.value = true
        loadingShow()
        
        return client(finalUrl.value, 
          {
                ...options,
                headers:{
                  'Accept': 'application/json',
                  'Accept-Language': locale,
                  ...extraOptions?.headers       
                }
                , 
                credentials: 'include',
                onResponseError: ({response}) => {
                    error.value = response
                    if(extraOptions?.onError != undefined) {
                        extraOptions?.onError(response)
                    }
                }
            }
          )
            .then((res: any) => {
                data.value = res
                if(extraOptions?.onSuccess != undefined) {
                    extraOptions?.onSuccess(res)
                }
            })
            .finally(() => {
                loading.value = false
                if(extraOptions?.onFinish != undefined) {
                    extraOptions?.onFinish()
                }
                loadingHide()
            })
    }

    function post(body?: any, options?: {
        onSuccess?: (res: any) => void,
        onError?: (err: any) => void,
        onFinish?: () => void,
        headers?: Record<string, string|number>
    }) {
        f({method: 'POST', body: body, withCredentials: true}, options)
    }

    function get(params?: any, options?: {
        onSuccess?: (res: any) => void,
        onError?: (err: any) => void,
        onFinish?: () => void,
        headers?: Record<string, string|number>
    }){
        f({method: 'GET', params: {...params}, withCredentials: true}, options)
    }

    function put(body?: any, options?: {
      onSuccess?: (res: any) => void,
      onError?: (err: any) => void,
      onFinish?: () => void,
      headers?: Record<string, string|number>
  }) {
        f({method: 'PUT', body: body, withCredentials: true}, options)
  }
  
  function destroy(body?:any, options?: {
      onSuccess?: (res: any) => void,
      onError?: (err: any) => void,
      onFinish?: () => void,
      headers?: Record<string, string|number>
  }) {
        f({method: 'DELETE', body:body, withCredentials: true}, options)
  }

  return {data, error, loading, setUrl, post, get, put, destroy}

}
