<script setup lang="ts">
const {t,locale,availableLocales, setLocale} = useI18n()

const languageStore = useLanguageStore()
 const languageStoreData = storeToRefs(languageStore)

 const {data:favicon, get:fetchFavIcon}=useOFetchCustom(`settings/favicon`)

 onMounted(()=>{
  setLocale(languageStoreData.language.value)
  fetchFavIcon()
 })

</script>

<template>
  <div>
    <head>
      <link rel="icon" type="image/x-icon" :href="favicon">
    </head>
    <NuxtLayout >
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
