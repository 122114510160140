export const listOfNotProtectedRoutes = [
    "/",
    "/store",
    "/store/[store]",
    "/auth/login",
    "/auth/register",
    "/auth/resetpassword",
    "/auth/forgotpassword",
    "/auth/verifyemail",
    "/auth/confirmPassword",
    "/cart",
    "/products/[Products]",
    "/information/about",
    "/contact-us"
]

export const listOfNotProtectedWithParamsRoutes =[
    "/products/[product]",
    "/operation/[operation]/redirect-page",
    "/operation/[operation]/success-page",
    "/orders/[order]/redirect-page",
    "/orders/[order]/success-page",
    "/operation/[operation]/show",
    "/information/[InformationPage]"
]

export const listOfProtectedRoutes = {
    "/users" : ["read_users"],
    "/users/create": ["create_users"],
    "/companies": ["read_companies"],
    "/companies/create": ["create_companies"],
    "/roles": ["read_roles"],
    "/roles/create": ["create_roles"],
    "/pointOfSales": ["read_pos"],
    "/pointOfSales/create": ["create_roles"],
    "/categories": ["read_categories"],
    "/categories/create": ["create_categories"],
    "/orderStatuses": ["read_orderStatuses"],
    "/orderStatuses/create": ["create_orderStatuses"],
    "/orders": ["read_orders"],
    "/invoices": ["read_invoices"],
    "/invoices/create": ["create_invoices"],
    "/products": ["read_products"],
    "/products/create": ["create_products"],
    "/banners": ["read_banners"],
    "/banners/create": ["create_banners"],
    "/report/sale": ["read_for_sale_reports"],
    "/report/inventory": ["read_for_inventory_reports"],
    "/report/movements": ["read_for_movements_reports"],
    "/report/saleProduct": ["read_for_sold_products"],
    "/settings/generals": ["update_settings"],
    "/settings/customization": ["update_settings"],
    "/coins": ["read_coins"],
    "/offers": ["read_offers"],
    "/offers/create": ["create_offers"],
    "/reports/sale":["read_for_sale_reports"],
    "/reports/inventory":["read_for_inventary_reports"],
    "/reports/soldProduct":["read_for_sold_products"],
    "/reports/movements":["read_for_movement_reports"],
    "/operation":["read_operation"],
    "/reservations":["read_reservation"]


}

export const listOfProtectedWithParamsRoutes = {
    "/users/[user]/edit": ["update_users"],
    "/banners/[id]/edit": ["update_banners"],
    "/categories/[category]/edit": ["update_categories"],
    "/companies/[company]/show": ["update_companies"],
    "/offers/[discount]/edit": ["update_offers"],
    "/order/[order]/show": ["read_order"],
    "/orderStatuses/[status]/edit": ["update_orderStatuses"],
    "/pointOfSales/[id]/edit": ["read_pos","update_pos"],
    "/roles/[role]/edit": ["read_roles"],
    "/products/[slug]/edit": ["read_products","update_products"],
    "/orders/[order]/show": ["read_orders"],
    "/coins/[id]/edit":["update_coins"],
    "/operation/[operation]/show":["read_operation"],
    "/reservations/[reservation]/show":["read_reservation"]
}