import type {User} from "~/models/User";
import { inferRouteParams, inferRouteParamsFree } from "~/common/helperRoutes";
import {listOfNotProtectedRoutes} from "~/common/routes";


export default defineNuxtRouteMiddleware(async (to, from) => {
    console.log(`output->`,inferRouteParamsFree(to.path, to.params))
    if(listOfNotProtectedRoutes.includes(to.path) || inferRouteParamsFree(to.path, to.params)){
        return;
    }
    const user = useSanctumUser<User>()
    const permission = inferRouteParams(to.path,to.params)
    let autorize = false
    if(permission.length > 0){
        autorize = permission.every(element => user.value?.permissions.includes(element))
        if(!autorize){
            
            return navigateTo('/store');
            
        }
        }else {
            return navigateTo('/store')
            
        }

    return;
})